import { Link } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'
import logo from '../../assets/images/logo.png'
import { motion } from 'framer-motion'

const Page = styled(motion.div)`
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    color: black;
    font-weight: 700;
`

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

const Logo = styled.div`
    width: calc(192px * 01.5);
    height: calc(100px * 01.5);

    background-image: url(${logo});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    z-index: 1;
`

const InputGroup = styled.div`
    margin: 10px 0px;  
    width: 100%  
`

const FormGroup = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    & > span {
        white-space: nowrap;
        display: block;
        &:not(:first-child):not(:last-child) {
            border-radius: 0;
        }
        &:first-child {
            border-radius: 100px 0 0 100px;
        }
        &:last-child {
            border-radius: 0 100px 100px 0;
        }
        &:not(:first-child) {
            margin-left: -1px;
        }
    }
    & > span {
        text-align: center;
        padding: 8px 12px;
        font-size: 14px;
        line-height: 25px;
        color: rgb(255, 255, 255);
        background: transparent;
        border: 1px solid rgb(255, 255, 255);
        transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
    }
    &:focus-within {
        & > span {
            color: #000;
            background: #EFE9E9;
            border-color: rgb(255, 255, 255);
        }
    }
    
    ${props => props.shake && css`
        animation: ${shake} 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    `}
    
`

const StyledInput = styled.input`
    display: block;
    width: calc(100% - 32px);
    padding: 8px 16px;
    line-height: 25px;
    font-size: 18px;
    font-weight: 500;
    font-family: inherit;
    border-radius: 6px;
    -webkit-appearance: none;
    color: #eaeaea;
    border: 1px solid rgb(255, 255, 255);
    background: transparent;
    transition: border .3s ease;
    &::placeholder {
        color: #eaeaea;
    }
    &:focus {
        outline: none;
        border-color: rgb(255, 255, 255);
    }

    white-space: nowrap;
    display: block;
    &:not(:first-child):not(:last-child) {
        border-radius: 0;
    }
    &:first-child {
        border-radius: 100px 0 0 100px;
    }
    &:last-child {
        border-radius: 0 100px 100px 0;
    }
    &:not(:first-child) {
        margin-left: -1px;
    }

    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    width: 1%;
    margin-top: 0;
    margin-bottom: 0;
`

const Border = styled.span`
    color: white;
    // text-shadow: 3px 0px 0 #FFF,2.837451725101904px 0.9740984076140504px 0 #FFF,2.3674215281891806px 1.8426381380690033px 0 #FFF,1.6408444743672808px 2.5114994347875856px 0 #FFF,0.7364564614223977px 2.908200797817991px 0 #FFF,-0.24773803641699682px 2.9897534790200098px 0 #FFF,-1.2050862739589083px 2.747319979965172px 0 #FFF,-2.0318447148772227px 2.2071717320193955px 0 #FFF,-2.638421253619467px 1.427842179111221px 0 #FFF,-2.959083910208167px 0.4937837708422021px 0 #FFF,-2.959083910208167px -0.4937837708422014px 0 #FFF,-2.6384212536194678px -1.4278421791112192px 0 #FFF,-2.031844714877223px -2.2071717320193946px 0 #FFF,-1.2050862739589072px -2.747319979965173px 0 #FFF,-0.2477380364169982px -2.9897534790200098px 0 #FFF,0.7364564614223964px -2.9082007978179916px 0 #FFF,1.6408444743672796px -2.511499434787586px 0 #FFF,2.3674215281891815px -1.842638138069002px 0 #FFF,2.837451725101904px -0.9740984076140512px 0 #FFF;
`

const BorderLink = styled(Link)`
    // text-shadow: 3px 0px 0 #FFF,2.837451725101904px 0.9740984076140504px 0 #FFF,2.3674215281891806px 1.8426381380690033px 0 #FFF,1.6408444743672808px 2.5114994347875856px 0 #FFF,0.7364564614223977px 2.908200797817991px 0 #FFF,-0.24773803641699682px 2.9897534790200098px 0 #FFF,-1.2050862739589083px 2.747319979965172px 0 #FFF,-2.0318447148772227px 2.2071717320193955px 0 #FFF,-2.638421253619467px 1.427842179111221px 0 #FFF,-2.959083910208167px 0.4937837708422021px 0 #FFF,-2.959083910208167px -0.4937837708422014px 0 #FFF,-2.6384212536194678px -1.4278421791112192px 0 #FFF,-2.031844714877223px -2.2071717320193946px 0 #FFF,-1.2050862739589072px -2.747319979965173px 0 #FFF,-0.2477380364169982px -2.9897534790200098px 0 #FFF,0.7364564614223964px -2.9082007978179916px 0 #FFF,1.6408444743672796px -2.511499434787586px 0 #FFF,2.3674215281891815px -1.842638138069002px 0 #FFF,2.837451725101904px -0.9740984076140512px 0 #FFF;
    text-decoration: none;
    color: white;
`

const rotate = keyframes`
  0%, 100% {
    transform: rotate(-5deg);
  }

  50% {
    transform: rotate(5deg);
  }
`

const Image = styled.div`
    width: 25%;
    padding-bottom: 39%;
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    border-radius: 15px;

    animation: ${rotate} 1s linear infinite;
`

export {
    Page,
    Logo,

    Border, BorderLink,

    InputGroup, FormGroup, StyledInput,

    Image
}
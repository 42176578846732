import styled from 'styled-components';

import { IoCloseOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

// import stars from '../../../assets/images/stars.jpg'
import grapefruit from '../../../assets/images/grapefruit.png'

const Container = styled(motion.div)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 500px;

    overflow: scroll;

    background-color: #EFE9E9;
`

const EventImage = styled.div`
    // width: 85%;
    // padding-bottom: 51%;
    // background-image: url(${props => props.img});
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center;
    // border-radius: 15px 15px;
    // width: 100%;
    // height 200px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    width: 85%;
    height: 200px;
    border-radius: 15px;
    margin: 10px 0;
`

const ToggleMarketPlace = styled.div`
    width: 80%;
    padding: 10px 0;
    margin: 5% 0;
    display: flex; 
    justify-content: space-between;
    align-items: center;

    font-size: 1.1em;

    border: 1px solid #86A3B8;
    border-radius: 100px;
`

const Item = styled.div`
    transition: 0.3s ease all;

    width: 50%;
    height: calc(100% + 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    // font-weight: ${props => props.active ? 'bold' : 'normal'};

    color: ${props => props.active ? '#5F6F94' : '#82899C'};

    border-radius: 100px;
    background-color: ${props => props.active ? '#86A3B8' : 'none'};
`

const ListContainer = styled(motion.div)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
`

const Wrapper = styled.div`
    width: 70%;
    height: 20%;
    max-height: 60px;
    min-height: 60px;
    margin: 4px 0;
    // border: 1px solid grey;
    display: flex; 
    align-items: center;
    justify-content: center;

    background-color:  rgb(226, 226, 226); // ${props => props.isEven ? "#232323" : "#fff"};
    border: 1px solid #232323;

    border-radius: 100px;
    text-decoration: none;

    color: black;
    // color: white;
`

const WrapperLink = styled(Link)`
    width: 80%;
    height: 20%;
    max-height: 80px;
    min-height: 80px;
    margin: 8px 0;
    // border: 1px solid grey;
    display: flex; 
    align-items: center;
    justify-content: center;

    background-color: #181818;

    // border-radius: 12px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-decoration: none;

    color: black;
    // color: white;
`

const Informative = styled.div`
    width: 80%;
    height: 100%;

    display: flex; 
    align-items: center;
    justify-content: space-between;

    text-decoration: none;
`

const TicketInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 50%;

    color: black;
`

const QuantitySelector = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60px;
    font-weight: bold;

    color: white;
`

const Description = styled.div`
    font-family: HelveticaNeue;
    font-weight: 500;
    color: black; // ${props => props.isEven ? "#fff" : "#232323"};
    font-size: 0.75em;
`

const Price = styled.div`
    font-family: HelveticaNeue;
    font-weight: 300;
    text-align: center;
    font-size: 0.8em;
    color: black; // ${props => props.isEven ? "#fff" : "#232323"};
`

const X = styled(IoCloseOutline)`
    position: fixed;
    top: 20px;
    left: 20px;
    font-size: 3em;
`

const ImageWrapper = styled.div`
    width: 20%;

    display: flex;
    justify-content: center;
`

const SellerImage = styled.div`
    height: 35px;
    width: 35px;

    border-radius: 100%;

    background-image: url('${props => props.img}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`

const ErrorIcon = styled.div`
    height: 150px;
    width: 180px;
    opacity: 1;

    background-image: url(${grapefruit});
    background-size: cover;
    background-position: center;
    text-decoration: none;
`

const Button = styled.div`
    font-family: HelveticaNeue;
    font-weight: 400;
    background-color: #232323;
    // background-color: #FD5D16;
    border-radius: 100px;
    font-size: 1.4rem;
    width: 70%;

    max-height: 60px;
    min-height: 60px;

    align-self: center;
    
    text-align: center;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100px;
    // color: black;
    color: white;
    text-decoration: none;

    // border: 1px solid rgb(0,0,0);

    z-index: 2;

    margin: 16px 0;
`

const InfoIcon = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: rgb(35, 48, 77);
    height: 50px;
    width: 50px;
    border-radius: calc(50px / 2);
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
`

export {
    Container,
    EventImage,
    ListContainer, 
    ToggleMarketPlace, 
    Item,
    Wrapper, 
    WrapperLink, 
    Informative,
    TicketInfo,
    Description, 
    Price, 
    X,
    ImageWrapper,
    SellerImage,
    QuantitySelector,
    Button,
    ErrorIcon,
    InfoIcon
};
import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { 
    Page,
    Logo,
    InputGroup,
    FormGroup,
    StyledInput,
    Border,
    Image,
    // BorderLink,
    // Disk,
    // Headphones,
} from "./styles";
import { Link, Navigate, useNavigate } from "react-router-dom";
import guests from "./guests";

import logo from '../../assets/images/logo.png';
import sticker from '../../assets/images/sticker.png';

import { BsFillPhoneFill, BsPhone } from 'react-icons/bs'

const HomeValidateGuests = () => {
    const [number, setNumber] = useState("")
    // const [submission, setSubmission] = useState(false)
    const [user, setUser] = useState(null)
    const [invalid, setInvalid] = useState(false)
    const access = guests;

    let navigate = useNavigate();

    const submit = () => {
        let currentUser = access.find(
            u => number?.toString().replace(/\D/g, "")
            .endsWith( u.number?.toString().replace(/\D/g, "").slice(-8) )
        )
        
        if (currentUser) {
            setUser(currentUser)
            localStorage.setItem("valid", "true");
            localStorage.setItem("number", number);
            localStorage.setItem("name", currentUser.name);
            navigate("/events")
        } else {
            setInvalid(true)
            setNumber("")
            setTimeout(() => {setInvalid(false)}, 500)
        }
    }

    useEffect(() => {
        let cachedNumber = localStorage.getItem("number")
        if (cachedNumber) {
            setNumber(cachedNumber)
        }
    }, [])

    return (
    <Page>
        <img src={logo} alt="Logo" style={{
            width: "70%",
            maxWidth: "300px",
            zIndex: 10
        }}/>

        <video style={{
            position: "fixed",
            top: "0",
            left: "0",
            display: "block",
            width: "100vw",
            height: "100vh",
            "OobjectFit": "cover",
            "objectFit": "cover",
            zIndex: "-2"
        }} src="https://gandaya.b-cdn.net/home_tangerica.mp4" autoPlay loop muted playsInline></video>
        <div style={{
            position: 'fixed',
            bottom: '50px',
            fontFamily: 'abegnale', letterSpacing: "0.3em",
            zIndex: "50", display: 'flex', justifyContent: 'center', 
            // transform: "rotate(5deg)"
        }}>
            <motion.div key="phone-form" initial={{ x: -300, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: -300, opacity: 0 }} transition={{ duration: 0.2 }} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <InputGroup style={{fontFamily: "Abegnale", letterSpacing: "0.05rem", paddingTop: "4px"}}>
                <FormGroup shake={invalid}>
                    {/* <span>🇧🇷 +55</span> */}
                    <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}> <BsPhone /> </span>
                    <StyledInput id="whats" name="whatsapp" type="tel" placeholder="ex. 11999999999" value={number} onChange={(e) => setNumber(e.target.value)} />
                </FormGroup>
            </InputGroup>
            <span style={{
                display: "block",
                fontSize: "1.5em",
                marginTop: "0.3em",
                marginBottom: "0.83em",
                marginLeft: "0",
                marginRight: "0",
                fontWeight: "300",
                fontFamily: "HelveticaNeue", letterSpacing: "0.05rem", paddingTop: "4px",
                color: "white",
                position: "relative", zIndex: "2"
            }} className="myoutlinedtext" onClick={submit}>Entrar</span>
        </motion.div>
        </div>
    </Page>
    )
}

export default HomeValidateGuests;
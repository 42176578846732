import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { BsDashLg, BsInfoLg, BsPlusLg } from "react-icons/bs";

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper'
import 'swiper/css';

import {
    Container,
    ListContainer, 
    ToggleMarketPlace,
    Item, 
    Wrapper, 
    WrapperLink,
    Description, 
    Price, 
    SellerImage,
    ImageWrapper,
    Informative,
    TicketInfo,
    QuantitySelector,
    Button,
    // EventImage,
    // InfoIcon,
    // ErrorIcon
} from './styles';

import client from "../../../assets/service/client";
import { useQuery } from "react-query";
import Loader from "../../../assets/components/loader/Loader";
import { AnimatePresence } from "framer-motion";
// import InfoModal from "./modal/InfoModal";
// import { Logo } from "../eventList/styles";
// import FastImageLooper from "../eventList/ImageLooper";

// import title from '../../../assets/images/STICKER_FINAL-01.png';
// import rabisco from '../../../assets/images/RABISCO_FINAL-01.png';
// import art1 from '../../../assets/images/gdya_1.png';
// import art2 from '../../../assets/images/gdya_2.png';
// import art3 from '../../../assets/images/gdya_3.png';
// import art4 from '../../../assets/images/gdya_4.png';
// import art5 from '../../../assets/images/gdya_5.png';

const TicketList = () => {
    let [showTickets, setShowTickets] = useState(true);
    let [showOrders, setShowOrders] = useState(false);
    let [showInfo, setShowInfo] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    let alias = "tangerica";

    let promo = searchParams.get("promo");
    let error = searchParams.get("error");

    let [quantity, setQuantity] = useState({});
    let [products, setProducts] = useState([]);

    const getQuantity = (ticketSpecId) => {
        return quantity[ticketSpecId] || 0;
    }

    const incrementQuantity = (ticketSpecId, multiplier, orderLimit) => {
        let delta = multiplier || 1
        let limit = orderLimit || Infinity

        let updatedQuantity = {}
        updatedQuantity[ticketSpecId] = quantity[ticketSpecId] ? quantity[ticketSpecId] + delta : delta
        updatedQuantity[ticketSpecId] = updatedQuantity[ticketSpecId] > limit ? limit : updatedQuantity[ticketSpecId]

        setQuantity(quantity => ({
            ...quantity,
            ...updatedQuantity
        }))
    }

    const decrementQuantity = (ticketSpecId, multiplier) => {
        let delta = multiplier || 1
        let updatedQuantity = {}
        updatedQuantity[ticketSpecId] = quantity[ticketSpecId] && quantity[ticketSpecId] - delta

        setQuantity(quantity => ({
            ...quantity,
            ...updatedQuantity
        }))
    }

    const parseProducts = (tickets, promo) => {
        let state = []
        for (let ticketSpecId of Object.keys(quantity)) {
            if (quantity[ticketSpecId]) {
                let ticketSpec = tickets.filter(t => t.id == ticketSpecId)[0]
                state.push({
                    ...ticketSpec, quantity: quantity[ticketSpecId], codeUsed: promo
                })
            }
        } 

        setProducts([...state]);
    }

    useEffect(() => {
        if (products.length) {
            document.getElementById("link_to_checkout").click()
        }
    }, [products])

    const getEventByAlias = async () => {
        return await client.get(`event/alias/${alias}`)
        .then(res => {
            if (res.data.event.pixelId) {
                window.fbq('init', `${res.data.event.pixelId}`);
                window.fbq('trackSingle', `${res.data.event.pixelId}`, 'ViewContent')
                window.fbq('trackSingle', process.env.REACT_APP_PIXEL_ID, 'ViewContent');
            }
            return(res.data.event);
        })
        .catch(err => err)
    }

    const getActiveTickets = async (eventId) => {
        return await client.get(`event/${eventId}`)
        .then(res => {
            return(res.data.event.activeTicketSpecs);
        })
        .catch(err => err)
    }

    const getPromoTickets = async (eventId) => {
        if (promo) {
            return await client.get(`ticket/event/${eventId}/promo/${promo}`)
            .then(res => {
                return(res.data.ticketSpecs);
            })
            .catch(err => err)
        } else return null
    }

    const getEventOrders = async (eventId) => {
        return await client.get(`event/${eventId}/orders`)
        .then(res => {
            return(res.data.orders);
        })
        .catch(err => err)
    }

    const { isLoading: isEventLoading, isError: isEventError, data: event, error: eventError } = useQuery(['event', alias], getEventByAlias, { keepPreviousData: false });
    const { isLoading: isTicketsLoading, isError: isTicketsError, data: tickets, error: ticketsError } = useQuery(['eventTickets', alias], () => getActiveTickets(event.id), {enabled: (!!showTickets) && (!!event), keepPreviousData: false});

    const { isLoading: isPromoLoading, isError: isPromoError, data: promoTickets, error: promoError } = useQuery(['eventTicketsPromo', alias], () => getPromoTickets(event.id), {enabled: (!!showTickets) && (!!event), keepPreviousData: false});

    const { isLoading: isOrdersLoading, isError: isOrdersError, data: orders, error: ordersError } = useQuery(['eventOrders', alias], () => getEventOrders(event.id), {enabled: (!!showOrders) && (!!event), keepPreviousData: false});

    if (isEventLoading || (showTickets && isTicketsLoading) || (showOrders && isOrdersLoading)) {
        return (
            <Container>
                <Loader />
            </Container>
        )
    }

    SwiperCore.use([Autoplay]);
    return (
        <Container className="no-scroll" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
            <div style={{minHeight:"60px"}} />

            {/* <Swiper
                spaceBetween={50}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                }}
                style={{width: "100%"}}
                loop={true}
            >
                
                <SwiperSlide key={1} style={{display:'flex', justifyContent: 'center', alignItems: "center", width: "100%"}}>
                    <div style={{
                        width: "95%",
                        paddingBottom: "32%",
                        backgroundImage: `url(/sticker_tange_1.png)`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                    }}/>
                </SwiperSlide>
                
                <SwiperSlide key={2} style={{display:'flex', justifyContent: 'center', alignItems: "center", width: "100%"}}>
                    <div style={{
                        width: "95%",
                        paddingBottom: "32%",
                        backgroundImage: `url(/sticker_tange_2.png)`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                    }}/>
                </SwiperSlide>

                <SwiperSlide key={3} style={{display:'flex', justifyContent: 'center', alignItems: "center", width: "100%"}}>
                    <div style={{
                        width: "95%",
                        paddingBottom: "32%",
                        backgroundImage: `url(/sticker_tange_3.png)`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                    }}/>
                </SwiperSlide>

                <SwiperSlide key={4} style={{display:'flex', justifyContent: 'center', alignItems: "center", width: "100%"}}>
                    <div style={{
                        width: "95%",
                        paddingBottom: "32%",
                        backgroundImage: `url(/sticker_tange_4.png)`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                    }}/>
                </SwiperSlide>

                <SwiperSlide key={5} style={{display:'flex', justifyContent: 'center', alignItems: "center", width: "100%"}}>
                    <div style={{
                        width: "95%",
                        paddingBottom: "32%",
                        backgroundImage: `url(/sticker_tange_5.png)`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                    }}/>
                </SwiperSlide>

            </Swiper> */}

            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                style={{width: "100%"}}
                loop={true}
            >
                {
                [
                    {"key": 1, "img": "/camoes.png"},
                    {"key": 2, "img": "/deep.png"},
                    {"key": 3, "img": "/kingdom.png"},
                    {"key": 4, "img": "/gaspar.png"},
                    {"key": 5, "img": "/discos.png"},
                ].map(img => {
                    return (
                        <SwiperSlide key={img.key} style={{display:'flex', justifyContent: 'center', alignItems: "center", width: "100%"}}>
                            <div style={{
                                width: "95%",
                                paddingBottom: "150%",
                                backgroundImage: `url(${img.img})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                            }}/>
                        </SwiperSlide>
                    )
                })
                }
            </Swiper>

            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "fixed", bottom: "0px", zIndex: "20"
                }}
            >
                <AnimatePresence exitBeforeEnter={true} initial={false}>
                {
                    error && <div key="randomkey" style={{fontSize: "0.8em", width: "80%", textAlign: "center", marginBottom: "16px", color: "#181818"}}>Os ingressos selecionados não estão mais disponíveis. Por favor, selecione novamente seus ingressos.</div>
                }
                {
                    showTickets && (tickets || promoTickets) && (
                        <>
                        <ListContainer key="event-tickets" initial={{ x: -300, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: -300, opacity: 0 }} transition={{ duration: 0.2 }}>
                        {
                        !!tickets && !!tickets?.length &&
                            tickets.map((ticket, index) => {
                                const isEven = index % 2 === 0
                                return (
                                    <Wrapper key={ticket.id} isEven={isEven}>
                                        <Informative>
                                            <TicketInfo>
                                                <Description isEven={isEven}>{
                                                ticket.description.includes("Masc") ? "Ingresso Masc ~ Open Bar" : 
                                                ticket.description.includes("Fem") ? "Ingresso Fem ~ Open Bar" :
                                                ticket.description
                                                }</Description>
                                                <Price isEven={isEven}>R${ticket.price}</Price>
                                            </TicketInfo>
                                            <QuantitySelector>
                                                <BsDashLg onClick={() => decrementQuantity(ticket.id, ticket.multiplier)} style={{"fontSize": "0.8em", "color": isEven ? "#FD5D16" : "#232323"}}/> 
                                                <span style={{"color": isEven ? "#000" : "#232323"}}>{getQuantity(ticket.id)}</span>
                                                <BsPlusLg onClick={() => incrementQuantity(ticket.id, ticket.multiplier, ticket.orderLimit)} style={{"fontSize": "0.8em", "color": isEven ? "#FD5D16" : "#232323"}}/>
                                            </QuantitySelector>
                                        </Informative>
                                    </Wrapper>
                                )
                            })
                        }
                        {
                        !!promoTickets && !!promoTickets?.length &&
                            promoTickets.map((ticket, index) => {
                                if (!!tickets && !!tickets?.length && tickets.map(t => t.id).includes(ticket.id)) { return <></> }
                                const isEven = (index + tickets.length) % 2 === 0
                                return (
                                    <Wrapper key={ticket.id} isEven={isEven}>
                                        <Informative>
                                            <TicketInfo>
                                                <Description isEven={isEven}>{ticket.description}</Description>
                                                <Price isEven={isEven}>R${ticket.price}</Price>
                                            </TicketInfo>
                                            <QuantitySelector>
                                                <BsDashLg onClick={() => decrementQuantity(ticket.id, ticket.multiplier)} style={{"fontSize": "0.8em", "color": isEven ? "#FD5D16" : "#232323"}}/> 
                                                <span style={{color: "#000"}}>{getQuantity(ticket.id)}</span>
                                                <BsPlusLg onClick={() => incrementQuantity(ticket.id, ticket.multiplier, ticket.orderLimit)} style={{"fontSize": "0.8em", "color": isEven ? "#FD5D16" : "#232323"}}/>
                                            </QuantitySelector>
                                        </Informative>
                                    </Wrapper>
                                )
                            })
                        }
                        </ListContainer>
                        
                        <Button onClick={() => parseProducts(promoTickets?.length ? [...tickets, ...promoTickets] : tickets, promo)}>
                            comprar
                        </Button>
                        <Link id="link_to_checkout" style={{"display": "none"}} to={`/events/${alias}/tickets/checkout`} state={{ products }}></Link>
                        </>
                    )
                }
                {
                    showOrders && orders && (
                        <ListContainer key="event-orders" initial={{ x: 300, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: 300, opacity: 0 }} transition={{ duration: 0.2 }}>
                        {
                        orders.length ?
                            orders.map(order => {
                                return (
                                    <WrapperLink to={`/events/${event.id}/order/${order.id}/checkout`} key={order.id}>
                                        <Informative>
                                        <ImageWrapper>
                                            <SellerImage img={`${process.env.REACT_APP_S3_URL}/${order.seller.profileImage.key}`}/>
                                        </ImageWrapper>
                                        <Description>{order.ticket.ticketSpec.description}</Description>
                                        <Price>R${order.price}</Price>
                                        </Informative>
                                    </WrapperLink>
                                )
                            })
                        :
                            <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <span>Não Há Ingressos Disponíveis</span>
                            </div>
                        }
                        </ListContainer>
                    )
                }
                </AnimatePresence>
            </div>
        </Container>
    )
}

export default TicketList
import styled from 'styled-components';

const FormContainer = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 20px;
`

const Input = styled.input`
    margin: 7px 0;
        
    margin-top: 2px;
    display: block;
    width: calc(100% - 32px);
    padding: 8px 16px;
    line-height: 25px;
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;
    border-radius: 6px;
    -webkit-appearance: none;
    color: #99A3BA;
    border: 1px solid #CDD9ED;
    background: #fff;
    transition: border .3s ease;
    &::placeholder {
        color: #CBD1DC;
    }
    &:focus {
        outline: none;
        border-color: #275EFE;
    }
`

const Submit = styled.div`
    width: 120px;
    
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    font-family: HelveticaNeue;
    font-weight: 400;
    background-color: #FD5D16;
    border-radius: 100px;
    font-size: 0.8rem;
    // width: 60%;
    line-height: 1rem;

    align-self: center;
    
    text-align: center;
    cursor: pointer;

    border-radius: 100px;
    color: white;
    text-decoration: none;

    border: 1px solid rgb(255, 255, 255);

    padding: 10px 50px;
    padding-bottom: 7px;

    z-index: 2;

    margin-top: 16px;
`

export {
    FormContainer,
    Input,
    Submit
}
import styled from 'styled-components';

// import rvr from '../../images/RVR-113.jpg'

// import instagramRedirect from '../../images/insta_gif.gif'

const Container = styled.div`
    position: fixed;    
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 70;
`

const Modal = styled.div`
    width: 80%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`

const Form = styled.div`
    transition: 1s ease all;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 400px;
    max-height: 90%;
    width: 80%;
    min-width: 270px;
    max-width: 400px;

    // background-color: #181818;
    border: 1px solid black;

    color: #181818;

    border-radius: 5px;
    padding: 20px 10px;
`

export {
    Container,
    Modal,
    // InstagramBrowserRedirectGif,
    Form
}
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

// import stars from '../../../assets/images/stars.jpg'
// import arrow1 from '../../../assets/images/arrow1.gif'
// import arrow2 from '../../../assets/images/arrow2.gif'
// import arrow3 from '../../../assets/images/arrow3.gif'

import logo from '../../../assets/images/logo.png'

const Container = styled(motion.div)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* color: rgb(35, 48, 77); */
    background-color: #EFE9E9;
`

const Logo = styled.div`
    width: 100px;
    height: 50px;

    background-image: url(${logo});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    z-index: 10;
`

const ListContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Wrapper = styled.div`
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 20px 0;

    background-color: #181818;

    color: #fff;

    // border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

const Image = styled(Link)`
    width: 100%;
    height: 300px;
    border-radius: 15px 15px 0 0;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
`

const DescriptionWrapper = styled.div`
    height: 100px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Description = styled(Link)`
    height: 75%;
    width: 60%;
    display: flex;    
    flex-direction: column;
    justify-content: space-evenly;
    text-decoration: none;

    color: white;
`

const Title = styled.span`
    font-weight: bold;
    font-size: 1.2em;
`

const Date = styled.span`
    font-size: 0.8em;
    color: white;

    display: flex;
    align-items: center;
`

const SearchContainer = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-evenly;
`

const Icon = styled.span`
    width: 15%;
`

const SearchForm = styled.input`
    width: 70%;
    height: 70%;

    border: 0;
    outline: 0;

    border-bottom: 1.5px solid #6A4F4F;

    font-family: rajdhani;
    font-size: 1.3em;
    font-weight: bold;
`

const Shadow = styled.div`
    position: fixed;
    bottom: 40px;
    height: 100px;
    width: 100%;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #2C5697 100%);
    background-blend-mode: multiply;
    mix-blend-mode: multiply;
    opacity: 0.45;

    z-index: 1;
`

const gradient = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const Button = styled(Link)`
    font-family: Iowa;
    font-weight: 400;
    background-color: #181818;
    // background-color: #FD5D16;
    border-radius: 100px;
    font-size: 1.4rem;
    margin-top: 10%;
    max-width: 300px;
    line-height: 2rem;

    align-self: center;
    
    text-align: center;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100px;
    color: white;
    text-decoration: none;

    border: 1px solid rgb(255, 255, 255);

    padding: 10px 50px;
    // padding-bottom: 14px;

    z-index: 2;
`

const ButtonTicket1 = styled(Link)`
    background-color: #232323;
    border-radius: 100px;
    
    text-align: center;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-radius: 100px;
    color: white;
    text-decoration: none;

    padding: 10px 25px;
`

const ButtonTicket2 = styled(Link)`
    background-color: #fff;
    border-radius: 100px;

    text-align: center;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-radius: 100px;
    color: white;
    text-decoration: none;

    border: 1px solid #232323;

    padding: 10px 25px;
`

export {
    Container,
    ButtonTicket1,
    ButtonTicket2,
    ListContainer,
    Wrapper,
    Image,
    DescriptionWrapper,
    Description,
    Title,
    Date,

    SearchContainer,
    Icon,
    SearchForm,

    Shadow,
    Button,

    Logo,
    // Arrow1,
    // Arrow2,
    // Arrow3
}
import { useEffect, useRef } from "react";
import Typewriter from 'typewriter-effect';

import { useState } from "react";
import { 
    Page,
    Button,
    Logo,
} from "./styles";
import { useNavigate } from "react-router";

import logo from '../../assets/images/logo.png';
import sticker from '../../assets/images/sticker.png';

const Home = () => {
    const navigate = useNavigate();
    return (
    <Page onClick={() => navigate("/events/tangerica/tickets")}>
        {/* <Logo /> */}
        <img src={logo} alt="Logo" style={{
            width: "70%",
            maxWidth: "300px",
            zIndex: 10
        }}/>
        <img src={sticker} alt="Logo" style={{
            width: "250px",
            height: "250px",
            position: "fixed",
            bottom: "-50px", right: "-50px",
            transform: "rotate(-9deg)",
            zIndex: 10
        }}/>
        <span style={{
            fontFamily: "HelveticaNeue",
            fontWeight: 500,
            zIndex: 10,
            fontSize: "0.5rem",
            width: "85%",
            maxWidth: "400px",
            textAlign: "center",
            marginTop: "10px"
        }}>
            a quarta ediçao da tangerica vai acontecer no dia 25 de novembro, em sp, sé. preparamos um line-up nos moldes de chill baile: brasilidades, house e funk.
        </span>

        <video style={{
            position: "absolute",
            top: "0",
            left: "0",
            display: "block",
            width: "100vw",
            height: "100vh",
            "OobjectFit": "cover",
            "objectFit": "cover",
        }} src="https://gandaya.b-cdn.net/home_tangerica.mp4" autoPlay loop muted playsInline></video>

        <Button 
            to='/events/tangerica/tickets'>
                <span 
                    style={{
                        fontFamily: "HelveticaNeue", 
                        lineHeight: "1.65rem", 
                        marginRight: "7px",
                        paddingTop: "4px",
                        fontWeight: 400,
                        fontSize: "1.4rem",
                        color: "white"
                    }}>comprar ingressos</span> 
                    {/* <span
                        style={{
                            color: "white"
                        }}
                    >ingressos</span> */}
        </Button>
            
    </Page>
    )
}

export default Home;
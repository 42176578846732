import { Link } from 'react-router-dom'
import styled from 'styled-components'

import logo from '../../assets/images/logo.png';

const Page = styled.div`
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: white;
    font-weight: 700;
`

const Logo = styled.div`
    width: 200px;
    height: 100px;

    background-image: url(${logo});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    z-index: 1;
`

const Button = styled(Link)`
    font-family: Iowa;
    font-weight: 400;
    // background-color: #181818;
    // background-color: #FD5D16;
    border-radius: 100px;
    font-size: 1.4rem;
    margin-top: 10%;
    max-width: 300px;
    line-height: 2rem;

    align-self: center;

    text-align: center;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100px;
    color: white;
    text-decoration: none;

    border: 1px solid rgb(255, 255, 255);

    padding: 10px 50px;
    // padding-bottom: 14px;

    z-index: 2;
`

export {
    Page,
    Button,
    Logo
}